@font-face {
    font-family: Gotham;
    font-weight: normal;
    src: url(fonts/Gotham-Book.otf) format("opentype");
}

@font-face {
    font-family: Gotham;
    font-weight: bold;
    src: url(fonts/Gotham-Bold.otf) format("opentype");
}

@font-face {
    font-family: GothamLight;
    font-weight: normal;
    src: url(fonts/Gotham-Light.otf) format("opentype"),
         url(fonts/Gotham-Light.woff) format("woff"),
         url(fonts/Gotham-Light.ttf) format("truetype");
}

@font-face {
    font-family: GothamMedium;
    font-weight: normal;
    src: url(fonts/Gotham-Medium.otf) format("opentype");
}

@font-face {
    font-family: GothamBlack;
    font-weight: normal;
    src: url(fonts/Gotham-Black.woff) format("woff"),
         url(fonts/Gotham-Black.ttf) format("truetype");
}

@font-face {
    font-family: CenturyOldStyleStd;
    font-weight: normal;
    src: url(fonts/CenturyOldStyleStd-Regular.otf) format("opentype");
}

@font-face {
    font-family: MyriadPro;
    font-weight: normal;
    src: url(fonts/MyriadPro-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(fonts/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
    url(fonts/iconfont/MaterialIcons-Regular.woff) format('woff'),
    url(fonts/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

