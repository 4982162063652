@font-face { font-family:'gothamlight'; src:url('./fonts/gothamlight/gothamlight.otf') format('opentype'), url('./fonts/gothamlight/gothamlight.ttf') format('truetype'); }
@font-face { font-family:'futuraheavy'; src:url('./fonts/futuraheavy/futuraheavy.otf') format('opentype'), url('./fonts/futuraheavy/futuraheavy.ttf') format('truetype'); }

html { width:100%; min-width:320px; max-width:100vw; overflow-x:hidden; overflow-y:auto; font-size:16px; -webkit-tap-highlight-color:rgba(0,0,0,0); -webkit-tap-highlight-color: transparent; box-sizing:border-box;  }
body { width:100%; min-width:320px; max-width:100vw; overflow-x:hidden; overflow-y:auto; font-size:1rem; line-height:1.5rem; text-align:left; color:#000; background:#fff; padding:0; margin:0; font-family: 'gothamlight', sans-serif; box-sizing:border-box; }
#root { width:100%; min-width:320px; max-width:100vw; overflow-x:hidden; overflow-y:auto; }

input, textarea, select, option { font-family: 'gothamlight', sans-serif; outline:none; border:1px solid #888; }

a { text-decoration:none; color:black; }
a:hover { }

.image.image_resized img { width: 100%; }

.news-page a, .news-page a:link, .news-page a:visited { color:#000; background-color:rgba(32, 190, 225, 0.1); padding:2px 0; }
.news-page a:hover { color:#fff; background-color:rgb(32 190 225); border-bottom:3px solid rgb(32 190 225); }

.front-wrapper { width:100%; height:70vh; min-height:300px; background:#000; box-sizing:border-box; }
.front-shadow { position:absolute; left:0; top:0; width:100%; height:70vh; min-height:300px; background-image:linear-gradient( rgba(0,0,0,0.3), rgba(0,0,0,0.6) ) }
.front { position:absolute; width:100%; top:50%; left:50%; transform:translate(-50%, -50%); padding:0 10px 60px 10px; text-align:center; font-weight:400; text-shadow:0 5px 10px rgba(0,0,0,0.4); }



.company-info-grid { display:block; font-size:1rem; text-align:left; margin:0; }
@media only screen and (min-width : 800px) {
	.company-info-grid { display:grid; grid-auto-flow:row; grid-template-columns: 50% 50%; grid-template-rows: auto; }
}

/*
.company-info { -webkit-perspective:1; font-size:1rem; text-align:left; margin:0 30px; width:100%; max-width:1240px; column-width:30rem; column-gap: 2rem;  }
*/
.company-info { padding:0 30px; width:100%; box-sizing:border-box; }

.company-info thead { font-weight:700; }
.company-info td { padding:5px 30px 5px 0; }
.company-info td:nth-of-type(n+2) { text-align:right; }
.company-info table { -webkit-column-break-inside:avoid-column; page-break-inside:avoid-column; break-inside:avoid-column; -webkit-column-break-before:avoid-column; page-break-before:avoid-column; break-before:avoid-column; }
.company-info p { font-size:1rem; -webkit-column-break-inside:avoid-column; page-break-inside:avoid-column; break-inside:avoid-column; }
.company-info h3 { font-size:1.5rem; font-weight:700; margin:35px 0 15px 0; -webkit-column-break-after:avoid-column; page-break-after:avoid-column; break-after:avoid-column; }
/*
.company-info h3:first-of-type { margin-top:0; }
*/
.break { -webkit-column-break-before:column; page-break-before:column; break-before:column; }

strong { font-weight:400; padding:5px 0 5px 0; color:#000; }
p { font-size:1rem; }

h1 { font-weight:400; font-size:2rem; margin-top:40px; }
h2 { font-weight:400; font-size:1.75rem; }
h3 { font-weight:400; font-size:1.5rem; }
h4 { font-weight:400; font-size:1.25rem; }
@media only screen and (min-width : 800px) {
}


.pointer { cursor:pointer; }
.info-wrapper { margin: 0; background:#e7e7e7; width:100%; padding:0 0 60px 0; box-sizing:border-box; background:#e7e7e7; text-align:left; }
.info { display:inline-block; width:100%; box-sizing:border-box; text-align:left; padding:0 20px 0 10px; vertical-align:top; }

.two { display:inline-block; width:100%; box-sizing:border-box; text-align:left; padding:0 20px 0 10px; vertical-align:top; }
.three { display:inline-block; width:100%; box-sizing:border-box; text-align:left; padding:0 20px 0 10px; vertical-align:top; }
.four { display:inline-block; width:100%; box-sizing:border-box; text-align:left; padding:0 20px 0 10px; vertical-align:top; }

.blogs-list-block { display:inline-block; width:100%; padding:0; margin:10px; text-align:center; color:black; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); box-sizing:border-box;  }
@media only screen and ( min-width: 657px) {
	.blogs-list-block { width:98%; margin:10px 0 80px 0; }
}

.card-hover { table-layout:fixed; border-collapse:collapse; width:100%; box-sizing:border-box; box-shadow:0 3px 6px rgba(0,0,0,0); }
.card-hover:hover { box-shadow:0 6px 6px rgba(0,0,0,0.15); }

.products-wrapper { margin: 0; width:100%; padding:0 0 60px 0; box-sizing:border-box; background:#e7e7e7; text-align:left; }
.products { margin:auto; width:100%; padding:0 0 20px 0; max-width:1240px; box-sizing:border-box; }
.products-flex-box { display:block; }

.banner-parent { width:100%; height:28vh; overflow:hidden; cursor:'pointer' }
.banner-child { width:100%; height:100%; transition: all 1.2s; }
.banner-img { object-fit:cover; object-position:50% 0; width:100%; height:100%; }

.gallery-background { position:fixed; z-index:2; left:0; top:0; width:100%; height:100%; background:#333; box-sizing:border-box; user-select:none; }
.gallery-wrapper { position:absolute; user-select:none; left:50%; top:50%; transform:translate( -50%, -50% ); width:100%; height:100%; padding:0 0 20px 0; max-width:1240px; box-sizing:border-box; }
.gallery-image { position:absolute; backface-visibility:hidden; user-select:none; left:0; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }
.gallery-image-left { position:absolute; backface-visibility:hidden; user-select:none; left:-100%; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }
.gallery-image-right { position:absolute; backface-visibility:hidden; user-select:none; left:100%; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }

.gallery-close { position:absolute; right:0; top:0; cursor:pointer; width:50%; user-select:none; color:white; font-size:32px; }
.gallery-close > i { position:fixed; right:10px; top:10px; padding:20px; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-close > i:hover { background-color:rgba(0,0,0,0.5); }

.gallery-arrow-left { position:absolute; left:0; top:50%; transform:translateY(-50%); cursor:pointer; width:110px; height:120px; user-select:none; color:white; font-size:32px; }
.gallery-arrow-left > i { position:fixed; left:10px; top:50%; transform:translateY(-50%); padding:20px; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-arrow-left > i:hover { background-color:rgba(0,0,0,0.5); }

.gallery-arrow-right { position:absolute; right:0; top:50%; transform:translateY(-50%); cursor:pointer; width:110px; height:120px; user-select:none; color:white; font-size:32px; }
.gallery-arrow-right > i { position:fixed; right:10px; top:50%; transform:translateY(-50%); padding:20px; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-arrow-right > i:hover { background-color:rgba(0,0,0,0.5); }

.footer-wrapper { margin:0; width:100%; text-align:center; font-size:1rem; line-height:1.2rem; padding:20px 0 10px 0; background-color:#263238; color:white; vertical-align:top; box-sizing:border-box;  }
.footer { margin:auto; width:100%; max-width:1240px; padding:10px 10px 30px 10px; vertical-align:top; box-sizing:border-box;  }
.footer-flex-box { display:block; }
.footer-item-group { width:100%; padding:30px 40px 0 20px; box-sizing:border-box; }
.footer-item-group p { padding:10px 0 0 0; }
.footer-border-right { }

.mobile-only { display:block; }
.desktop-only { display:none; }

@media only screen and ( min-width: 961px ) and ( min-height: 412px ) {
	.info { width:49%; }
	.two { width:49%; }
	.three { width:32%; }
	.four { width:24%; }
	.products-flex-box { display:flex; align-items: center; justify-content: center; }
	/*
	.products-checkout { width:380px; min-width:380px; margin:-90px 30px 140px 20px; }
	.products-checkout-component { width:380px; min-width:380px; margin:-90px 30px 140px 20px; }
	*/
	.footer-flex-box { display:flex; }
	.footer-item-group { width:240px; min-width:240px; padding:10px 10px 0 20px; }
	.footer-border-right { border-right: 1px solid rgba(255,255,255,0.2); }
	.mobile-only { display:none; }
	.desktop-only { display:block; }
}

.shadow { -webkit-filter: drop-shadow( 0px 2.5px 5px rgba(0,0,0,0.6) ); filter: drop-shadow( 0px 2.5px 5px rgba(0,0,0,0.6) ); }

.security-grid {
	display:grid; 
	overflow:hidden; 
	grid-template-columns:auto; 
	grid-template-rows:auto auto;
	grid-template-areas: "orders" "graph" ; 
	width:100%;
	height:100%;
	box-sizing:border-box;
}
@media only screen and (min-width : 800px) {
	.security-grid {
		display:grid; 
		overflow:hidden; 
		grid-template-columns:390px auto; 
		grid-template-rows:auto;
		grid-template-areas: "orders graph" ; 
		width:100%;
		height:100%;
		box-sizing:border-box;
	}
}

/*
button.solid { color:white; background:#6ba9bd; border:2px solid #6ba9bd; margin:5px; }
*/

.hide-on-mobile { display:none; }
@media only screen and (min-width : 500px) {
	.hide-on-mobile { display:table-cell; }
}


.hide-on-dt { display:table-cell; }
@media only screen and (min-width : 500px) {
	.hide-on-dt { display:none; }
}

.show-on-small { display:none; }
@media only screen and (min-width : 0px) {
	.show-on-small { display:table-cell; }
}

.show-on-medium { display:none; }
@media only screen and (min-width : 700px) {
	.show-on-medium { display:table-cell; }
}

.show-on-large { display:none; }
@media only screen and (min-width : 900px) {
	.show-on-large { display:table-cell; }
}

.show-on-xl { display:none; }
@media only screen and (min-width : 1100px) {
	.show-on-xl { display:table-cell; }
}
