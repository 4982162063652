/* commonStyles.css */
/*
html { height:100%; min-height:100%; margin:0; padding:0; }
body { font-family:'Gotham', sans-serif; font-size:14px; margin:0; padding:0; box-sizing:border-box; overflow-y:scroll; overflow-x:hidden; text-align:center; }
*/

a { cursor:pointer; text-decoration:none; color:#6ba9bd; }

p { line-height: 175%; }
ul { list-style:inside; padding:0 0 0 10px; }
ol { padding:0 0 0 15px; text-align:left; }
ol > li { padding:5px 10px; }

.highlight:hover { background-color:#eee; } 

.table { display:table; padding:15px 0; box-sizing:border-box; font-size:14px; }
.row { display:table-row; box-sizing:border-box; vertical-align:top; cursor:pointer; text-align:left; }
.col { display:table-cell; box-sizing:border-box; text-decoration:none; color:black; vertical-align:top; } /* just the cell '.col' */
@media only screen and (min-width : 800px) {
	.table { display:table; padding:15px 0; box-sizing:border-box; font-size:14px; }
	.row { display:table-row; box-sizing:border-box; vertical-align:top; cursor:pointer; text-align:left;  }
	.col { display:table-cell; box-sizing:border-box; vertical-align:top; width:auto; } /* just the cell '.col' */
	.p66 { width:66.66%; }
	.p50 { width:50%; }
	.p35 { width:35%; }
	.p33 { width:33.33%; }
	.p30 { width:30%; }
	.p25 { width:25%; }
	.p20 { width:20%; }
}

@media only screen and (max-width : 799px) {
	.hide-on-mobile { display:none; }
}

@media only screen and (min-width : 800px) {
	.hide-on-dt { display:none; }
}

@media only screen and (min-width : 800px) {
	.hide-on-desktop { display:none; }
}

.table.border { border:solid 1px #ddd; }
.table .th { display:table-row; vertical-align:top; font-weight:bold; text-transform:uppercase; box-sizing:border-box; }
.table .th > .cell { padding:0 0 15px 0; }
.table .bold { font-weight:bold; box-sizing:border-box; }
.table .upper { text-transform:uppercase; }
.table .cell { display:table-cell; box-sizing:border-box; vertical-align:top; text-align:left; padding:10px; border-top:solid 1px #ddd; } /* formatted boxes */
.table .cell > i { font-size:20px; color:#999; padding:0 0 6px 0; }
.table .cell > i:hover { color:#000; }
.table .cell > .filename { display:inline-block; padding:4px; vertical-align:top; text-align:left; box-sizing:border-box; }

/*
article > summary > .text-content { padding:0 20px 10px 10px; font-size:12px; text-align:left; }
@media only screen and (min-width : 800px) {
	article > summary > .text-content { padding:0 20px 0 0; font-size:12px; text-align:left; }
}
*/

.table > .row > .center { text-align:center; }
.table > .row > .right { text-align:right; }
.table > .row > .bold { font-weight:bold; }

.security-grid {
	display:grid; 
	overflow:hidden; 
	grid-template-columns:auto; 
	grid-template-rows:auto auto;
	grid-template-areas: "orders" "graph" ; 
	width:100%;
	height:100%;
	box-sizing:border-box;
}
@media only screen and (min-width : 800px) {
	.security-grid {
		display:grid; 
		overflow:hidden; 
		grid-template-columns:390px auto; 
		grid-template-rows:auto;
		grid-template-areas: "orders graph" ; 
		width:100%;
		height:100%;
		box-sizing:border-box;
	}
}
